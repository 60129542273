import { Row, Col, FloatingLabel, Form } from "react-bootstrap";

import { checkDateWithToday, onHandleDefaultDateValue } from "Helpers";

export const convertLifetime = (lifetime, lifetimeType) => {
    switch (lifetimeType) {
        case "years":
            lifetime *= 365;
            break;
        case "months":
            lifetime *= 30;
            break;
        case "weeks":
            lifetime *= 7;
            break;
        default:
        case "days":
            break;
    }

    lifetime *= 24 * 60 * 60;

    const seconds = Math.floor(lifetime % 60);
    lifetime = Math.floor(lifetime / 60);
    const minutes = lifetime % 60;
    lifetime = Math.floor(lifetime / 60);
    const hours = lifetime % 24;
    lifetime = Math.floor(lifetime / 24);
    const days = lifetime;

    return `${days}.${hours}:${minutes}:${seconds}`;
};

export const getDaysFromLifeTime = (lifetime) => {
    return lifetime.substr(0, lifetime.indexOf("."));
};

export const ShowLicenseTypeBlock = (props) => {
    switch (props.dataBlock) {
        case "temporary":
            return (
                <Row>
                    <Col md>
                        <FloatingLabel label="Длительность" className="mb-2">
                            <Form.Control
                                {...props.register("lifetime", {
                                    required: "Поле необходимо заполнить",
                                    minLength: {
                                        value: 1,
                                        message:
                                            "Минимальная длительность лицензии не может быть меньше 1",
                                    },
                                    min: {
                                        value: 1,
                                        message:
                                            "Минимальная длительность лицензии не может быть меньше 1",
                                    },
                                })}
                                placeholder="Длительность"
                                defaultValue={
                                    props.hasOwnProperty("license") &&
                                    props.license.time_left
                                        ? getDaysFromLifeTime(
                                              props.license.time_left
                                          )
                                        : "30"
                                }
                                type="number"
                            />
                            {props.errors.lifetime && (
                                <div className="text-danger">
                                    {props.errors.lifetime.message}
                                </div>
                            )}
                        </FloatingLabel>
                    </Col>
                    <Col md>
                        <FloatingLabel
                            label="Единица измерения длительности"
                            className="mb-2"
                        >
                            <Form.Select
                                {...props.register("lifetimeType", {
                                    required:
                                        "Необходимо выбрать значение из списка",
                                })}
                                placeholder="Единица измерения длительности"
                                defaultValue={
                                    props.hasOwnProperty("license")
                                        ? "days"
                                        : null
                                }
                            >
                                <option value="days">Дни</option>
                                <option value="weeks">Недели</option>
                                <option value="months">Месяцы</option>
                                <option value="years">Годы</option>
                            </Form.Select>
                            {props.errors.lifetimeType && (
                                <div className="text-danger">
                                    {props.errors.lifetimeType.message}
                                </div>
                            )}
                        </FloatingLabel>
                    </Col>
                    <Col md>
                        <FloatingLabel label="Дата активации" className="mb-2">
                            <Form.Control
                                {...props.register("startDate", {
                                    required:
                                        "Необходимо указать дату активации лицензии",
                                    validate: {
                                        date: (value) =>
                                            checkDateWithToday(value) ||
                                            "Дата должна быть не раньше сегодняшнего дня",
                                    },
                                })}
                                defaultValue={
                                    props.license?.entity?.lifetime?.startDate
                                        ? onHandleDefaultDateValue(
                                              props.license?.entity?.lifetime
                                                  ?.startDate
                                          )
                                        : onHandleDefaultDateValue(new Date())
                                }
                                placeholder="Дата активации"
                                type="date"
                            />
                            {props.errors.startDate && (
                                <div className="text-danger">
                                    {props.errors.startDate.message}
                                </div>
                            )}
                        </FloatingLabel>
                    </Col>
                </Row>
            );
        case "temporary_after_activation":
            return (
                <Row>
                    <Col md>
                        <FloatingLabel label="Длительность" className="mb-2">
                            <Form.Control
                                {...props.register("lifetime", {
                                    required: "Поле необходимо заполнить",
                                    minLength: {
                                        value: 1,
                                        message:
                                            "Минимальная длительность лицензии не может быть меньше 1",
                                    },
                                    min: {
                                        value: 1,
                                        message:
                                            "Минимальная длительность лицензии не может быть меньше 1",
                                    },
                                })}
                                placeholder="Длительность"
                                defaultValue={
                                    props.hasOwnProperty("license") &&
                                    props.license.time_left
                                        ? getDaysFromLifeTime(
                                              props.license.time_left
                                          )
                                        : "30"
                                }
                                type="number"
                            />
                            {props.errors.lifetime && (
                                <div className="text-danger">
                                    {props.errors.lifetime.message}
                                </div>
                            )}
                        </FloatingLabel>
                    </Col>
                    <Col md>
                        <FloatingLabel
                            label="Единица измерения длительности"
                            className="mb-2"
                        >
                            <Form.Select
                                {...props.register("lifetimeType", {
                                    required:
                                        "Необходимо выбрать значение из списка",
                                })}
                                placeholder="Единица измерения длительности"
                                defaultValue={
                                    props.hasOwnProperty("license")
                                        ? "days"
                                        : null
                                }
                            >
                                <option value="days">Дни</option>
                                <option value="weeks">Недели</option>
                                <option value="months">Месяцы</option>
                                <option value="years">Годы</option>
                            </Form.Select>
                            {props.errors.lifetimeType && (
                                <div className="text-danger">
                                    {props.errors.lifetimeType.message}
                                </div>
                            )}
                        </FloatingLabel>
                    </Col>
                </Row>
            );

        case "temporary_from_date":
            return (
                <Row>
                    <Col md>
                        <FloatingLabel label="Дата активации" className="mb-2">
                            <Form.Control
                                {...props.register("startDate", {
                                    required:
                                        "Необходимо указать дату активации лицензии",
                                    validate: {
                                        date: (value) =>
                                            checkDateWithToday(value) ||
                                            "Дата должна быть не раньше сегодняшнего дня",
                                    },
                                })}
                                defaultValue={
                                    props.license?.entity?.lifetime?.startDate
                                        ? onHandleDefaultDateValue(
                                              props.license?.entity?.lifetime
                                                  ?.startDate
                                          )
                                        : onHandleDefaultDateValue(new Date())
                                }
                                placeholder="Дата активации"
                                type="date"
                            />
                            {props.errors.startDate && (
                                <div className="text-danger">
                                    {props.errors.startDate.message}
                                </div>
                            )}
                        </FloatingLabel>
                    </Col>
                    <Col md>
                        <FloatingLabel
                            label="Дата окончания лицензии"
                            className="mb-2"
                        >
                            <Form.Control
                                type="date"
                                {...props.register("endDate", {
                                    required:
                                        "Необходимо указать дату окончания лицензии",
                                    validate: {
                                        date: (value) =>
                                            checkDateWithToday(value) ||
                                            "Дата должна быть не раньше сегодняшнего дня",
                                    },
                                })}
                                defaultValue={
                                    props.license?.entity?.lifetime?.endDate
                                        ? onHandleDefaultDateValue(
                                              props.license?.entity?.lifetime
                                                  ?.endDate
                                          )
                                        : onHandleDefaultDateValue(new Date())
                                }
                            />
                            {props.errors.endDate && (
                                <div className="text-danger">
                                    {props.errors.endDate.message}
                                </div>
                            )}
                        </FloatingLabel>
                    </Col>
                </Row>
            );
        case "countered":
            return (
                <Row>
                    <Col md>
                        <FloatingLabel
                            label="Количество запусков"
                            className="mb-2"
                        >
                            <Form.Control
                                {...props.register("counter", {
                                    required: "Поле необходимо заполнить",
                                    minLength: {
                                        value: 1,
                                        message:
                                            "Минимальная значение запусков не может быть меньше 1",
                                    },
                                    min: {
                                        value: 1,
                                        message:
                                            "Минимальная значение запусков не может быть меньше 1",
                                    },
                                })}
                                placeholder="Количество запусков"
                                defaultValue={
                                    props.hasOwnProperty("license") &&
                                    props.license?.entity?.lifetime?.counter
                                        ? props.license?.entity?.lifetime
                                              ?.counter
                                        : "5"
                                }
                                type="number"
                            />
                            {props.errors.counter && (
                                <div className="text-danger">
                                    {props.errors.counter.message}
                                </div>
                            )}
                        </FloatingLabel>
                    </Col>
                    <Col md>
                        <FloatingLabel
                            label="Количество активаций"
                            className="mb-2"
                        >
                            <Form.Control
                                {...props.register("activationCount", {
                                    required: "Поле необходимо заполнить",
                                    minLength: {
                                        value: 1,
                                        message:
                                            "Минимальная значение активаций не может быть меньше 1",
                                    },
                                    min: {
                                        value: 1,
                                        message:
                                            "Минимальная значение активаций не может быть меньше 1",
                                    },
                                })}
                                placeholder="Количество активаций"
                                defaultValue={
                                    props.hasOwnProperty("license") &&
                                    props.license.entity?.activationCount
                                        ? props.license.entity?.activationCount
                                        : "5"
                                }
                                type="number"
                            />
                            {props.errors.activationCount && (
                                <div className="text-danger">
                                    {props.errors.activationCount.message}
                                </div>
                            )}
                        </FloatingLabel>
                    </Col>
                </Row>
            );

        case "unlimited":
        default:
            return null;
    }
};
