import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table as BTable, Alert } from "react-bootstrap";

import { getLicenseType } from "Helpers";
import { GetLicense } from "Api";
import NotFound from "Components/NotFound";
import Loading from "Components/Loading";

function LicenseView(props) {
    const [license, setLicense] = useState("");
    const [loading, setLoading] = useState(true);

    const params = useParams();

    useEffect(() => {
        (async () => {
            const license = await GetLicense(params.licenseId);
            setLicense(license);

            setLoading(false);
        })();
    }, [params.licenseId]);

    if (loading) {
        return <Loading />;
    }

    if (license) {
        const {
            id,
            createDate,
            productId,
            userIds,
            lifetime,
            activationCount,
            isDeleted,
            licenseTypeCode,
        } = license.entity;
        return (
            <div className="container-fluid">
                <h3>Просмотр лицензии - {id}</h3>
                <BTable>
                    <tbody>
                        <tr>
                            <td>ID</td>
                            <td>{id}</td>
                        </tr>
                        <tr>
                            <td>Продукт</td>
                            <td>
                                {license._product ? (
                                    <Alert.Link
                                        href={
                                            "/product/view/" +
                                            license._product.id
                                        }
                                    >
                                        {license._product.name}
                                    </Alert.Link>
                                ) : (
                                    <span>
                                        Неизвестен (productId: {productId})
                                    </span>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Пользователь</td>
                            <td>
                                {license._user ? (
                                    <Alert.Link
                                        href={"/user/view/" + license._user.id}
                                    >
                                        {license._user.username}
                                    </Alert.Link>
                                ) : (
                                    <span>
                                        Неизвестен (userId:{" "}
                                        {userIds ? userIds[0] : null})
                                    </span>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Дата создания</td>
                            <td>
                                {new Date(createDate)
                                    .toLocaleString()
                                    .replace(/[,]/g, "")}
                            </td>
                        </tr>
                        <tr>
                            <td>Тип лицензии</td>
                            <td>{getLicenseType(licenseTypeCode)}</td>
                        </tr>
                        {lifetime?.lifetime &&
                        (licenseTypeCode === "temporary" ||
                            licenseTypeCode ===
                                "temporary_after_activation") ? (
                            <tr>
                                <td>Осталось времени</td>
                                <td>{lifetime?.lifetime}</td>
                            </tr>
                        ) : null}
                        {licenseTypeCode === "countered" ? (
                            <tr>
                                <td>Осталось запусков</td>
                                <td>{lifetime?.counter}</td>
                            </tr>
                        ) : null}
                        <tr>
                            <td>Количество активаций</td>
                            <td>{activationCount}</td>
                        </tr>
                        <tr>
                            <td>Статус</td>
                            <td>{isDeleted ? "Удалена" : "Активна"}</td>
                        </tr>
                    </tbody>
                </BTable>
            </div>
        );
    } else {
        return <NotFound />;
    }
}

export default LicenseView;
