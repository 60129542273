import React from 'react';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy, usePagination } from 'react-table';
import BTable from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import { Alert } from 'react-bootstrap';
import { getProductFromProductList, getUserFromUserList } from 'Helpers';

/**
 * В текущем компоненте был добавлен функционал отображения "лицензий с ошибками"
 * Пока что не ясно нужно ли их отображать и будут ли они приходить с бека.
 * 
 * При рендере таблицы прописан switch case по window.location.pathname (роут) по тому что
 * для лицензий есть новое поле isValid, которого нет у других списков
 * 
 * ссылка на задачу https://youtrack.smuit.ru/issue/AISC-286
 */

const IndeterminateCheckbox = React.forwardRef(
	({ indeterminate, ...rest }, ref) => {
		const defaultRef  = React.useRef();
		const resolvedRef = ref || defaultRef;

		React.useEffect(() => {
			resolvedRef.current.indeterminate = indeterminate
		}, [resolvedRef, indeterminate]);

		return (
			<div className="form-check form-check-inline cursor-pointer">
				<input className="form-check-input" type="checkbox" id="checkIndeterminate" ref={resolvedRef} {...rest} />
				<label className="form-check-label cursor-pointer" htmlFor="checkIndeterminate">Показать все</label>
			</div>
		);
	}
);

export default function Table({ columns, data, options, userList, productList = Object }) {
	const baseOptions = {
		pagination:    true,
		filter:        true,
		sorting:       true,
		hideColumns:   true,
		tableStriped:  true,
		tableBordered: true,
		tableHover:    true,
	};

	const mergedOptions = {...baseOptions, ...options};

	const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) => {
		const count = preGlobalFilteredRows.length;
		const [value, setValue] = React.useState(globalFilter);
		const onChange = useAsyncDebounce(value => {
			setGlobalFilter(value || undefined)
		}, 200);

		return (
			<input className="form-control"
				value={value || ''}
				onChange={e => {
					setValue(e.target.value);
					onChange(e.target.value);
				}}
				placeholder={`Поиск по ${count} элементам...`}
			/>
		);
	}

	const DefaultColumnFilter = ({ column: { filterValue, preFilteredRows, setFilter } }) => {
		const count = preFilteredRows.length;

		return (
			<input
				value={filterValue || ''}
				onChange={e => {
					setFilter(e.target.value || undefined)
				}}
				placeholder={`Поиск ${count} записей...`}
			/>
		);
	}

	const defaultColumn = React.useMemo(() => ({
		Filter: DefaultColumnFilter,
	}), []);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		page,
		prepareRow,
		visibleColumns,
		preGlobalFilteredRows,
		setGlobalFilter,
		getToggleHideAllColumnsProps,
		allColumns,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination,
	);

	const tableContent = mergedOptions.pagination ? page : rows;

	const pageSizeVariants = [];
	[10, 20, 30, 40, 50].map((value) => {
		if (value < data.length) {
			pageSizeVariants.push(value);
		}
		return false;
	});
	pageSizeVariants.push(data.length);

	return (
		<>
			{mergedOptions.hideColumns
				? <div className="pb-1">
					<IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />
					{allColumns.map(column => (
						<div className="form-check form-check-inline cursor-pointer" key={column.id}>
							<input className="form-check-input" type="checkbox" id={'checkIndeterminate' + column.id} {...column.getToggleHiddenProps()} />
							<label className="form-check-label cursor-pointer" htmlFor={'checkIndeterminate' + column.id}>{column.Header}</label>
						</div>
					))}
				</div>
				: null
			}
			<BTable striped={mergedOptions.tableStriped} bordered={mergedOptions.tableBordered} hover={mergedOptions.tableHover} {...getTableProps()}>
				<thead>
					{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{mergedOptions.sorting
								? headerGroup.headers.map(column => (
									<th {...column.getHeaderProps(column.getSortByToggleProps())}>
										{column.render('Header')}
										{mergedOptions.sorting
											? <span>
												{column.isSorted
													? column.isSortedDesc
														? ' ↓'
														: ' ↑'
													: ''
												}
											</span>
											: null
										}
										{!column.canFilter ? <div>column.render('Filter')</div> : null}
									</th>
								))
								: headerGroup.headers.map(column => (
									<th {...column.getHeaderProps()}>
										{column.render('Header')}
										{!column.canFilter ? <div>column.render('Filter')</div> : null}
									</th>
								))
							}
						</tr>
					))}
					{mergedOptions.filter
						?
							<tr>
								<th colSpan={visibleColumns.length}>
									<GlobalFilter
										preGlobalFilteredRows={preGlobalFilteredRows}
										globalFilter={state.globalFilter}
										setGlobalFilter={setGlobalFilter}
									/>
								</th>
							</tr>
						:
							null
					}
				</thead>
				<tbody {...getTableBodyProps()}>
					{tableContent.map((row, i) => {
						prepareRow(row);


						return (
							<tr {...row.getRowProps()} className={row.original.lineThrough ? 'text-decoration-line-through' : ''}>
								{row.cells.map(cell => {
									// Доп проверки для того чтобы работала сортировка по value и не по id
									if (cell?.column?.id === "user_link") {
										let licenseUser = getUserFromUserList(userList, cell.value);
										return <td {...cell.getCellProps()}>                 
										<Alert.Link href={"/user/view/" + cell?.value}>
											{licenseUser?.username}
										</Alert.Link>
										</td>
									}
									if (cell?.column?.id === "product_link") {
										let licenseProduct = getProductFromProductList(productList, cell.value);
										return <td {...cell.getCellProps()}>                 
										<Alert.Link href={"/product/view/" + cell?.value}>
											{licenseProduct?.name}
										</Alert.Link>
										</td>
									}
									return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
								})}
							</tr>
						);						
					})}
				</tbody>
			</BTable>

			{mergedOptions.pagination
				? <Pagination className="justify-content-end">
					<Pagination.First onClick={() => {
						document.getElementById('currentPage').value = '1';
						gotoPage(0);
					}} disabled={!canPreviousPage} title="Первая страница" />
					<Pagination.Prev onClick={() => {
						document.getElementById('currentPage').value = pageIndex;
						previousPage();
					}} disabled={!canPreviousPage} title="Назад" />

					<li className="page-item">
						<input
							id="currentPage"
							className="page-link"
							type="number"
							defaultValue={pageIndex + 1}
							onChange={e => {
								const page = e.target.value ? Number(e.target.value) - 1 : 0;
								gotoPage(page);
							}}
							style={{ width: '70px' }}
						/>
					</li>
					<li className="page-item">
						<span className="page-link" style={{ fontWeight: 'bold' }}>
							Страница {pageIndex + 1} из {pageOptions.length !== 0 ? pageOptions.length : 1}
						</span>
					</li>
					<li className="page-item">
						<select
							className="page-link"
							value={pageSize}
							onChange={e => {
								gotoPage(0);
								document.getElementById('currentPage').value = '1';
								let pageSizeSelect = Number(e.target.value);
								if (pageSizeSelect === -1) {
									document.getElementById('currentPage').disabled = true;
								} else {
									document.getElementById('currentPage').disabled = false;
								}
								setPageSize(pageSizeSelect);
							}}
						>
						{pageSizeVariants.map(pageSize => (
							<option key={pageSize} value={pageSize}>
								Показывать {pageSize === data.length ? 'все' : 'по ' + pageSize}
							</option>
						))}
						</select>
					</li>

					<Pagination.Next onClick={() => {
						document.getElementById('currentPage').value = pageIndex + 2;
						nextPage();
					}} disabled={!canNextPage} title="Вперед" />
					<Pagination.Last onClick={() => {
						document.getElementById('currentPage').value = pageOptions.length;
						gotoPage(pageCount - 1);
					}} disabled={!canNextPage} title="Последняя страница" />
				</Pagination>
				: null
			}
		</>
	);
}
