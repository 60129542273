import React, { useState, useEffect } from "react";
import { FloatingLabel, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { GetProductList, GetUserList } from "Api";
import { getLicenseType } from "Helpers";
import Loading from "Components/Loading";
import { ApiRequest } from "Api";
import { convertLifetime, ShowLicenseTypeBlock } from "./common";

function LicenseAdd(props) {
    const [userList, setUserList] = useState([]);
    const [productList, setProductList] = useState([]);

    const [dataBlock, setDataBlock] = useState("");

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        formState,
    } = useForm();
    const { isSubmitting } = formState;

    useEffect(() => {
        (async () => {
            const productList = await GetProductList();
            setProductList(productList.items);

            const userList = await GetUserList();
            setUserList(userList.items);
        })();
    }, []);

    const licenseTypeList = [
        "unlimited",
        "temporary",
        "temporary_after_activation",
        "temporary_from_date",
        "countered",
    ];

    function onHandleDataForAdditionLicense(incomingData) {
        // TODO: Настроить нормальный обработчик
        // https://youtrack.smuit.ru/issue/AISC-286
        const outledData = {};
        for (const key in incomingData) {
            if (key !== "licenseTypeCode" && key !== "lifetimeType") {
                outledData[key] = incomingData[key];
            }
        }
        if (outledData.startDate)
            outledData.startDate = new Date(
                incomingData.startDate
            ).toISOString();
        if (outledData.endDate)
            outledData.endDate = new Date(incomingData.endDate).toISOString();
        return outledData;
    }

    const onSubmit = async (data, e) => {
        e.preventDefault();

        if (
            data.hasOwnProperty("lifetime") &&
            data.lifetime &&
            data.hasOwnProperty("lifetimeType") &&
            data.lifetimeType
        ) {
            data.lifetime = convertLifetime(data.lifetime, data.lifetimeType);
        }

        const outletData = onHandleDataForAdditionLicense(data);
        try {
            await ApiRequest("license.add", outletData)
                .then((response) => {
                    e.target.reset();
                    props.history.push("/license");
                })
                .catch((error) => {
                    console.log('Response "license.add" error:', error);
                    if (error.response.data.error === "License already exist") {
                        document.getElementById("errorMessage").innerHTML =
                            "Не удалось создать лицензию. Лицензия для данного пользователя и продукта уже существует.";
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };

    const onReset = () => {
        reset();
    };

    if (userList && productList) {
        userList.sort((a, b) => (a.username > b.username ? 1 : -1));
        productList.sort((a, b) => (a.name > b.name ? 1 : -1));

        return (
            <div className="container-fluid">
                <h3>Создание лицензии</h3>
                <div className="text-danger mb-2" id="errorMessage"></div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <FloatingLabel label="Продукт" className="mb-2">
                        <Form.Select
                            {...register("productId", {
                                required:
                                    "Необходимо выбрать значение из списка",
                            })}
                            placeholder="Продукт"
                        >
                            <option value="">Выберите продукт</option>
                            {productList.map((option, index) => {
                                return (
                                    <option key={index} value={option.id}>
                                        {option.name}
                                    </option>
                                );
                            })}
                        </Form.Select>
                        {errors.productId && (
                            <div className="text-danger">
                                {errors.productId.message}
                            </div>
                        )}
                    </FloatingLabel>
                    <FloatingLabel label="Пользователь" className="mb-2">
                        <Form.Select
                            {...register("userId", {
                                required:
                                    "Необходимо выбрать значение из списка",
                            })}
                            placeholder="Пользователь"
                        >
                            <option value="">Выберите пользователя</option>
                            {userList.map((option, index) => {
                                return (
                                    <option key={index} value={option.id}>
                                        {option.username}
                                    </option>
                                );
                            })}
                        </Form.Select>
                        {errors.userId && (
                            <div className="text-danger">
                                {errors.userId.message}
                            </div>
                        )}
                    </FloatingLabel>
                    <FloatingLabel label="Тип лицензии" className="mb-2">
                        <Form.Select
                            {...register("licenseTypeCode", {
                                required:
                                    "Необходимо выбрать значение из списка",
                                onChange: (e) => {
                                    setDataBlock(e.target.value);
                                },
                            })}
                            placeholder="Тип лицензии"
                        >
                            <option value="">Выберите тип лицензии</option>
                            {licenseTypeList.map((option, index) => {
                                return (
                                    <option key={index} value={option}>
                                        {getLicenseType(option)}
                                    </option>
                                );
                            })}
                        </Form.Select>
                        {errors.licenseTypeCode && (
                            <div className="text-danger">
                                {errors.licenseTypeCode.message}
                            </div>
                        )}
                    </FloatingLabel>

                    <ShowLicenseTypeBlock
                        dataBlock={dataBlock}
                        register={register}
                        errors={errors}
                    />

                    <Button
                        variant="outline-success"
                        type="submit"
                        className="me-2"
                    >
                        {isSubmitting && (
                            <span className="spinner-border spinner-border-sm me-1"></span>
                        )}
                        Создать
                    </Button>
                    <Button
                        variant="outline-danger"
                        type="reset"
                        onClick={onReset}
                    >
                        Сбросить
                    </Button>
                </Form>
            </div>
        );
    } else {
        return <Loading />;
    }
}

export default LicenseAdd;
