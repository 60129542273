import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";

import Table from "Components/Table";
import {
    getLicenseType,
    getUserFromUserList,
    getProductFromProductList,
} from "Helpers";
import {
    GetProductList,
    GetUserList,
    GetLicenseList,
    DeleteLicense,
} from "Api";
import Loading from "Components/Loading";
import ViewButton from "Components/Buttons/ViewButton";
import EditButton from "Components/Buttons/EditButton";
import DeleteRestoreButton from "Components/Buttons/DeleteRestoreButton";

const tableHead = [
    { accessor: "id", Header: "ID" },
    { accessor: "product_link", Header: "Продукт" },
    { accessor: "user_link", Header: "Пользователь" },
    { accessor: "create_date", Header: "Дата создания" },
    { accessor: "license_type", Header: "Тип лицензии" },
    { accessor: "activation_count", Header: "Количество активаций" },
    { accessor: "actions", Header: "Действие" },
];

function LicenseList(props) {
    const [licenseList, setLicenseList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [productList, setProductList] = useState([]);

    useEffect(() => {
        (async () => {
            const productList = await GetProductList({ withDeleted: true });
            setProductList(productList?.items);

            const userList = await GetUserList();
            setUserList(userList?.items);

            const licenseList = await GetLicenseList({
                onlyActive: false,
                withLifetime: false,
            });
            setLicenseList(licenseList?.entity?.items);
        })();
    }, []);

    if (licenseList?.length) {
        let preparedLicenseList = [];

        let length = licenseList.length;
        for (var i = 0; i < length; i++) {
            const {
                id,
                userIds,
                productId,
                validationResults,
                licenseTypeCode,
                createDate,
                activationCount,
                isDeleted,
            } = licenseList[i].entity;

            const isValid = licenseList[i].isValid || false;
            const userId = userIds && userIds.length ? userIds[0] : null;
            const errorMessage =
                validationResults && validationResults.length
                    ? validationResults[0]?.errorMessage
                    : null;

            let licenseProduct = getProductFromProductList(
                productList,
                productId
            );
            let licenseUser = getUserFromUserList(userList, userId);
            preparedLicenseList[i] = {
                errorMessage,
                isValid,
                id,
                product_link: licenseProduct?.id,
                user_link: licenseUser?.id,
                create_date: createDate
                    ? new Date(createDate).toLocaleString().replace(/[,]/g, "")
                    : null,
                license_type: getLicenseType(licenseTypeCode),
                activation_count: activationCount || null,
                lineThrough: isDeleted || false,
                actions: (
                    <>
                        <ViewButton
                            href={"/license/view/" + id}
                            title={"Просмотр лицензии " + id}
                            size="sm"
                        />
                        {!isDeleted ? (
                            <EditButton
                                href={"/license/edit/" + id}
                                size="sm"
                                title={"Изменить лицензию " + id}
                            />
                        ) : null}
                        <DeleteRestoreButton
                            id={id}
                            actionData={{ userId, productId }}
                            actionDelete={DeleteLicense}
                            name="лицензию"
                            size="sm"
                            entityName={id}
                            title={true}
                            isDeleted={isDeleted}
                        />
                    </>
                ),
            };
        }

        return (
            <div className="container-fluid">
                <h3>Список лицензий</h3>
                <Table columns={tableHead} data={preparedLicenseList} userList={userList} productList={productList}/>
            </div>
        );
    } else {
        return <Loading />;
    }
}

export default LicenseList;
