import React from 'react';
import { urlConfig } from 'Config/envSettings';

function StatsLogs(props) {
	return (
		<div style={{ height: '100vh' }}>
			<h3 className="container-fluid">Статистика по логам</h3>
				<iframe
					src={urlConfig.logsURL}
					width="100%"
					frameBorder="0"
					scrolling="no"
					style={{ height: '100vh' }}
					title="Elastic"
				></iframe>
		</div>
	);
}

export default StatsLogs;
