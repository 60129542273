import { ApiRequest } from "Api";
import {
    getLicenseFromLicenseList,
    getUserFromUserList,
    getUserFromUserListByEmail,
    getCompanyFromCompanyList,
    getCompanyFromCompanyListByEmail,
} from "../Helpers";

function catchError(error) {
    if (error?.response?.status === 500 && error?.response?.data?.error) {
        console.log(
            'Response error "' + error?.response?.request?.responseURL + '":',
            error?.response?.data?.error
        );
    } else {
        console.log(
            'Response unknown "' +
                error?.response?.request?.responseURL +
                '" (' +
                error?.response?.status +
                ")"
        );
    }
}

export const GetLicenseList = async (options = Object) => {
    const baseOptions = {
        sortBy: ["id"],
        sortDesc: [false],
        page: 1,
        itemPerPage: 1000,
    };

    const mergedOptions = { ...baseOptions, ...options };

    const licenseList = await ApiRequest("license.list", mergedOptions)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            catchError(error);
        });

    return licenseList;
};

export const GetProductList = async (options = Object) => {
    const baseOptions = {
        page: 1,
        pageSize: 1000,
    };

    const mergedOptions = { ...baseOptions, ...options };

    const productList = await ApiRequest("product.list", mergedOptions)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            catchError(error);
        });

    return productList;
};

export const GetUserList = async (options = Object) => {
    const baseOptions = {
        sortBy: ["ID"],
        sortDesc: [false],
        page: 1,
        itemPerPage: -1,
    };

    const mergedOptions = { ...baseOptions, ...options };

    const userList = await ApiRequest("user.list", mergedOptions)
        .then((response) => {
            return JSON.parse(response.data);
        })
        .catch((error) => {
            catchError(error);
        });

    return userList;
};

export const GetCompanyList = async (options = Object) => {
    const baseOptions = {
        sortBy: ["ID"],
        sortDesc: [false],
        page: 1,
        itemPerPage: -1,
        onlyCompanies: true,
    };

    const mergedOptions = { ...baseOptions, ...options };

    const companyList = await ApiRequest("company.list", mergedOptions)
        .then((response) => {
            return JSON.parse(response.data);
        })
        .catch((error) => {
            catchError(error);
        });

    return companyList;
};

export const GetLicense = async (id) => {
    const userList = await GetUserList();
    const licenseList = await GetLicenseList({
        onlyActive: false,
        withLifetime: true,
    });

    const license = getLicenseFromLicenseList(licenseList?.entity?.items, id);

    if (license) {
        const licenseProduct = await GetProduct(license?.entity?.productId);
        const licenseUser = getUserFromUserList(
            userList.items,
            license?.entity?.userIds[0]
        );

        license._product = licenseProduct ? licenseProduct : null;
        license._user = licenseUser ? licenseUser : null;
    }

    return license ? license : null;
};

export const GetUser = async (id) => {
    const userList = await GetUserList();

    let user = getUserFromUserList(userList.items, id);

    if (!user) {
        user = getUserFromUserListByEmail(userList.items, id);
    }

    if (user) {
        const userRole = await ApiRequest("user.role", { userId: user.id })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log('Response "user.role" error.');
            });

        user._role = userRole ? userRole : null;
    }

    return user ? user : null;
};

export const GetCompany = async (id) => {
    const companyList = await GetCompanyList();

    let company = getCompanyFromCompanyList(companyList.items, id);

    if (!company) {
        company = getCompanyFromCompanyListByEmail(companyList.items, id);
    }

    return company ? company : null;
};

export const GetProduct = async (id) => {
    const product = await ApiRequest("product.get", { productId: id })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log('Response "product.get" error.');
        });

    return product ? product : null;
};

export const DeleteProduct = async (id) => {
    let result = false;

    try {
        result = await ApiRequest("product.delete", { id: id })
            .then((response) => {
                if (response.data && response.data.isValid) {
                    return true;
                }
            })
            .catch((error) => {
                console.log('Response "product.delete" error:', error);
            });
    } catch (error) {
        console.log(error);
    }

    return result;
};

export const RestoreProduct = async (id) => {
    let result = false;

    try {
        result = await ApiRequest("product.restore", { id: id })
            .then((response) => {
                if (response.data && response.data.isValid) {
                    return true;
                }
            })
            .catch((error) => {
                console.log('Response "product.restore" error:', error);
            });
    } catch (error) {
        console.log(error);
    }

    return result;
};

export const SetLicenseCounter = async (data) => {
    let result = false;

    try {
        result = await ApiRequest("license.set_counter", data)
            .then((response) => {
                return true;
            })
            .catch((error) => {
                console.log('Response "license.set_counter" error:', error);
            });
    } catch (error) {
        console.log(error);
    }

    return result;
};

export const DeleteLicense = async (data) => {
    let result = false;

    try {
        result = await ApiRequest("license.delete", data)
            .then((response) => {
                return true;
            })
            .catch((error) => {
                console.log('Response "license.delete" error:', error);
            });
    } catch (error) {
        console.log(error);
    }

    return result;
};

export const EditLicense = async (data) => {
    let result = false;

    try {
        result = await ApiRequest("license.edit", data)
            .then((response) => {
                return true;
            })
            .catch((error) => {
                console.log('Response "license.edit" error:', error);
            });
    } catch (error) {
        console.log(error);
    }

    return result;
};
